<template>
    <div>
        <VisitFilter v-if="$can('filter', 'visits')" :loading="loading" @searchFilter="searchFilter"
            @filterPage="filterPage" />
        <b-card>
            <TitleTable titleTable="Visits" iconTable="fas fa-disease" />
            <div class="m-1">
                <VisitEdit v-if="isEditVisitSidebarActive" :is-edit-visit-sidebar-active.sync="isEditVisitSidebarActive"
                    :itemEdit="itemEdit" @editVisitSuccess="editVisitSuccess"/>
                <VisitAdd :is-add-new-visit-sidebar-active.sync="isAddNewVisitSidebarActive" @createVisit="createVisit"
                    @mensaje="mensaje($event)" />
                <!-- Table Top -->
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <treeselect v-model="perPage" :options="perPageOptions" :clearable="true" class="w-25" />
                    </b-col>
                    <!-- {{ $t("Search") }} -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <GoBack class="mr-2" />
                            <b-button v-if="$can('store', 'visits')" variant="primary"
                                @click="isAddNewVisitSidebarActive = true" :disabled="loading">
                                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Visit</span>
                                <span v-else>
                                    <SpinnerLoading />
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table :items="items" :fields="fields" hover bordered responsive primary-key="id" class="position-relative"
                show-empty empty-text="No matching records found">
                <template #cell(color)="data">
                    <b-avatar size="32" :style="{ 'background-color': data.item.color }">
                        <span></span>
                    </b-avatar>
                </template>
                <template #cell(price)="data">
                    <span>{{ data.item.price ? data.item.price.price : '-' }}</span>
                </template>
                <template #cell(status)="data">
                    <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">{{
                            data.item.status ? $t("Active") : $t("Inactive")
                        }}</span>
                    </div>
                </template>
                <template #cell(actions)="data">
                    <b-button v-if="$can('update', 'visits')" variant="outline-primary" class="btn-icon btn-circle border-0"
                        v-b-tooltip.hover.top="'Edit'" @click="editVisit(data.item)">
                        <i class="fas fa-pen icon-nm"></i>
                    </b-button>

                    <b-button v-if="$can('destroy', 'visits')" variant="outline-danger" class="btn-icon btn-circle border-0"
                        v-b-tooltip.hover.top="'Delete'" @click="deleteVisit(data.item)">
                        <i class="fas fa-trash-alt icon-nm"></i>
                    </b-button>
                </template>
            </b-table>
            <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
                @page-changed="pageChanged" />
        </b-card>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BButton,
    VBTooltip,
} from "bootstrap-vue";
import axiosAC from "@/core/services/apiInsurance/admin/consults/insuranceName";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import PaginationTable from "@/components/PaginationTable";
import TitleTable from "@/components/TitleTable.vue";
import GoBack from "@/components/ButtonBack.vue";
import Swal from "sweetalert2";

import VisitEdit from "./NameEdit.vue";
import VisitAdd from "./NameAdd.vue";
import VisitFilter from "./NameFilter.vue";
import { ref } from "@vue/composition-api";
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BTable,
        BAvatar,
        BButton,
        VisitEdit,
        VisitAdd,
        VisitFilter,
        ToastNotification,
        SpinnerLoading,
        PaginationTable,
        TitleTable,
        GoBack,
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    data() {
        return {
            fields: [
                // A virtual column that doesn't exist in items
                "id",
                { key: "name", label: "Name", sortable: true },
                { key: "prefix", label: "Prefix", sortable: true },
                { key: "status", label: "Status" },
                // A virtual column made up from two fields
                { key: "actions", label: "Actions" },
            ],
            items: null,
            loading: false,
            isEditVisitSidebarActive: false,
            isAddNewVisitSidebarActive: false,
            itemEdit: null,
            dataMeta: { from: 1, to: 1, of: 1 },
            totalUsers: 0,
            perPage: 10,
            currentPage: {
                page: 1,
            },
            perPageOptions: [10, 25, 50, 100].map((i) => ({
                id: i,
                label: `Show ${i} Entries`,
            })),
            filtro: false,
            visitFilter: null,
        };
    },
    created() {
    },
    mounted() {
        this.getVisit();
    },
    watch: {
        perPage(newVal) {
            if (!this.filtro) {
                this.getVisit();
            } else {
                this.searchFilter(this.visitFilter);
            }
        },
    },
    methods: {
        getVisit() {
            axiosAC
                .visitList(this.perPage)
                .then(({ data, current_page, total, per_page, ...res }) => {
                    this.items = data;
                    this.totalUsers = total;
                    this.dataMeta.of = total;
                    this.dataMeta.from = res.from;
                    this.dataMeta.to = res.to;
                    this.currentPage.page = current_page;
                });
        },
        editVisit(item) {
            this.isEditVisitSidebarActive = true;
            this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
        },
        mensaje(value) {
            this.$refs.toast.danger(value);
        },
        editVisitSuccess(value) {
            if (value) {
                this.$refs.toast.success("Type Visit updated successfully");
            } else {
                this.$refs.toast.danger("Error updating type visit");
            }
            this.isEditVisitSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        createVisit({ status }) {
            if (status) {
                this.$refs.toast.success("Visit type added successfully");
            } else {
                this.$refs.toast.danger("Visit type not added");
            }
            this.isAddNewVisitSidebarActive = false;
            this.pageChanged(this.currentPage.page);
        },
        deleteVisit(item) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    axiosAC.visitDelete(item.id).then((response) => {
                        this.$refs.toast.success(
                            "Visit type has been deleted successfully"
                        );
                        this.pageChanged(this.currentPage.page);
                    });
                }
            });
        },
        pageChanged(page) {
            if (!this.filtro) {
                axiosAC
                    .visitPagination(this.perPage, page)
                    .then(({ data, total, current_page, ...res }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            } else {
                axiosAC
                    .visitFilterPagination(this.perPage, page, this.visitFilter)
                    .then(({ registro: { data, total, current_page, ...res } }) => {
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    });
            }
        },
        searchFilter(value) {
            if (this.filtro) {
                this.loading = true;
                this.visitFilter = value;
                axiosAC
                    .visitFilter(this.perPage, value)
                    .then(({ registro: { data, total, current_page, ...res } }) => {
                        this.loading = false;
                        this.items = data;
                        this.totalUsers = total;
                        this.dataMeta.of = total;
                        this.dataMeta.from = res.from;
                        this.dataMeta.to = res.to;
                        this.currentPage.page = current_page;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            } else {
                this.getVisit();
            }
        },
        filterPage(value) {
            this.filtro = value;
        },
    },
};
</script>

<style></style>
